import "./Cont.css";
import { Container, List, ListItem, Typography } from "@material-ui/core";

export default function ContVPSRec() {
  return (
    <div className="cont">
      <Container className="MainBox" maxWidth="sm">
        <List>
          <ListItem>
            <Typography variant="h2" style={{ fontSize: "1.2em" }}>
              Рекомендации к VPS
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" style={{ lineHeight: "50%" }}>
              <List>
                <ListItem>1 CPU, 512MB RAM</ListItem>
                <ListItem>Канал 100мбит/с</ListItem>
                <ListItem>Debian 11 или Ubuntu 20.04</ListItem>
                <br/>
                <ListItem><p style={{lineHeight:1.3}}><b>Заказать впс можно на проверенном хостере</b> <a href="https://aeza.net/?ref=457330" target="_blank" rel="noreferrer"> aeza.net</a></p></ListItem>
              </List>
            </Typography>
          </ListItem>
        </List>
      </Container>
    </div>
  );
}
